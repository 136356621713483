import React, { useState } from 'react';

async function CreateDeal(data, contact) {
    return fetch('https://lk.trbyte.ru/crm.php', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
                method: 'crm.deal.add',
                id: data['token'],
                name: data['name'],
                text: data['fullText'],
                category: data['category'],
                contact: contact,
                type: data['type'],
                time: data['time'],
            })
        })
        .then((resp) => resp.json())
        .then(function (data) {
            console.log(data.result);
        })
        .catch(function (error) {
            console.log(error)
        })
}

async function CreateTicket(data) {
    return fetch('https://lk.trbyte.ru/crm.php', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
                method: 'crm.contact.add',
                name: data['name'],
                phone:  data['phone'],
                mail:  data['mail'],
            })
        })
        .then(resp => resp.json())
        .then(
            (result) => {
                console.log(result);
                CreateDeal(data, result.result);
                
            },
            (error) => {
                console.log(error);
            }
        );    
}

const AddTicket = () => {
    const [message, setMessage] = useState('');
    const [name, setName] = useState();
    const [inn, setInn] = useState();
    const [type, setType] = useState();
    const [phone, setPhone] = useState();
    const [time, setTime] = useState();
    const [mail, setMail] = useState();
    const [info, setInfo] = useState();

    const [showResults, setShowResults] = useState(false);
    
    const handleSubmit = async e => {
        e.preventDefault();
        let fullText = '';
        
        fullText += 'ИНН: ' + inn + ', ';
        if(info) fullText += ', Доп. инфо: ' + info;
        
        CreateTicket({
            name,
            token: '12022',
            fullText,
            category: '36',
            type: type,
            time: time,
            phone: phone,
            mail: mail,
        });
        setMessage(`Клиент добавлен!`);
        setShowResults(true);

        setName('');
        setInn('');
        setInfo('');
        setPhone('');
        setMail('');
        setTimeout(() => setShowResults(false), 1000);
    }
    const Message = () => (
        <div className="message">
            {message}
        </div>
      )
    return(
        <form  className="tinkoff-form" onSubmit={handleSubmit} onClick={e => e.stopPropagation()}>
            { showResults ? <Message /> : null }
            <label>
                <input type="text" placeholder="ФИО клиента" value={name} onChange={e => setName(e.target.value)} />
            </label>
            <label>
                <input type="text" placeholder="ИНН*" value={inn}  required onChange={e => setInn(e.target.value)} />
            </label>
            <label>
                <select onChange={e => setType(e.target.value)}>
                    <option value="" disabled selected hidden>Источник лида:</option>
                    <option value="2228">Хорошилов В.Н.</option>
                    <option value="2230">Негадов Д.Ю.</option>
                    <option value="2232">АТОЛ</option>
                    <option value="2234">Иное</option>
                </select>
            </label>
            <label>
                <input type="text" placeholder="Телефон клиента*" value={phone} required onChange={e => setPhone(e.target.value)} />
            </label>
            <label>
                <input type="text" placeholder="Почта" value={mail} required onChange={e => setMail(e.target.value)} />
            </label>
            <label>
                <select onChange={e => setTime(e.target.value)}>
                    <option value="" disabled selected hidden>Срочность:</option>
                    <option value="2236">В течении 5 минут</option>
                    <option value="2238">В течении часа</option>
                    <option value="2240">В течении рабочего дня</option>
                </select>
            </label>
            <label>
                <textarea placeholder="Информация" value={info} onChange={e => setInfo(e.target.value)} />
            </label>
            <div>
                <button type="submit">Создать</button>
            </div>
        </form>
    )

}
export default AddTicket;