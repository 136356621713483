import React, { Component } from 'react';
export default class Ccomponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            isLoaded: false,
            data: []
        }
    }
    
    componentDidMount() {
        fetch('https://lk.trbyte.ru/crm.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                    method: 'crm.company.get',
                    id: this.props.token
                })
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState(
                    {
                        isLoaded: true,
                        data: result.result
                    }
                );
            },
            (error) => {
                this.setState({
                    isLoaded: false,
                    error
                });
            }
        );
    }
    
    render() {
        const {error, isLoaded, data} = this.state;
        if (error) {
            return <p>{error.message}</p>
        } else if (!isLoaded) {
            return <p>Loading</p>
        } else {
            return (
                <div>
                    {data.TITLE}
                </div>
            )
        }

    }
}
