import React, { Component }  from 'react';
import { Link } from 'react-router-dom';
export default class LeftMenu extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            error: null,
            isLoaded: false,
            company: this.props.company
        }
    }
    render() {
        var menu;
        if(this.state.company != 1500){
            menu = <Link to={'/my-business'} className="">Мой бизнес</Link>;
        } else {
            menu = <Link to={'/tinkoff'} className="">Тинькоф</Link>;
        }
        return (
            <div className="left">
                {menu}
            </div>
        )
    }
}    