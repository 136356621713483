import React, { useState } from 'react';
import Tickets from './components/ticketcat';
import Tinkoff from './components/tinkoff/tinkoffcat';
import Header from './components/header';
import Left from './components/leftMenu';
import Login from './components/AuthComp';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import useToken from './components/useToken';
import companyGet from './components/companyId';
import addNew from './components/tinkoff/addNew';
import vnNew from './components/vn_leads/addNew';
import ProtectedRoute from './components/ProtectedRoute';
import './App.css';

function App() {
  // const { token, setToken } = useToken();

  // if(!token) {
  //   return <Login setToken={setToken} />
  // } 
  // var company = localStorage.getItem('company');
  // if(!company) {
  //   companyGet(token);
  //   company = localStorage.getItem('company');
  // }

  return (
    <div>
      <BrowserRouter>
      <div className="header">
        <img src='/logo.webp'/> 
        {/* <Header token={token}/> */}
      </div>
      <div className="wrapper">
        {/* <Left company={company}/> */}
        <div className="right">
          <div className="content">
              <Switch>
                <Route exact path='/auth' component={Login}/>
                <Route exact path='/tinkoff' component={addNew}/>
                <Route exact path='/vn_leads' component={vnNew}/>
                <ProtectedRoute exact path="/"/>
                {/* <Route path="/my-business">
                  <Tickets token={token}/>
                </Route>
                <Route path="/tinkoff">
                  <Tinkoff token={token} />
                </Route> */}
              </Switch>
          </div>
        </div>
      </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
