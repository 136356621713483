import React, { useState } from 'react';

async function addComment(data) {
    
    return fetch('https://lk.trbyte.ru/crm.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
            method: 'crm.timeline.comment.add',
            id: data['id'],
            text: data['text'],
        })
    })
    .then(data => data.json())
    .then(
        (result) => {
            console.log(result);
            window.location.reload();
        },
        (error) => {
            console.log(error);
        }
    );

}

const AddComm = ({id}) => {
    const [text, setText] = useState();
    const handleSubmit = async e => {
        e.preventDefault();
        addComment({
            id,
            text,
        });
    }
    return(
        <form onSubmit={handleSubmit} onClick={e => e.stopPropagation()}>
            <label>
                <textarea placeholder="Комментарий" onChange={e => setText(e.target.value)} />
            </label>
            <div>
                <button type="submit">Отправить</button>
            </div>
        </form>
    )
}
export default AddComm;