import React, { Component } from 'react';
import { withRouter  } from "react-router-dom";
import AddComment from "./AddComment";
class Ticket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false
        }
    }
    
    componentDidMount() {
        const id = this.props.match.params.ID;
        fetch('https://lk.trbyte.ru/crm.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                    method: 'crm.deal.get',
                    id: id
                })
        })
        .then(res => res.json())
        .then(
            (result) => {
                console.log(result);
                this.setState(
                    {
                        isLoaded: true,
                        data: result.result
                    }
                );
            },
            (error) => {
                this.setState({
                    isLoaded: false,
                    error
                });
            }
        );
    }
    
    render() {
        
        const {error, isLoaded, data} = this.state;
        if (error) {
            return <p>{error.message}</p>
        } else if (!isLoaded) {
            return <p>Загрузка</p>
        } else {
            return (
                <div className="ticket">
                    <div className="ticket-head">
                        <div className="title">{data.TITLE} <span className="id">ID: {data.ID}</span></div>
                        <div className="create-modify">
                            <div>Создано: {data.DATE_CREATE}</div>
                            <div>Изменено: {data.DATE_MODIFY}</div>
                        </div>
                    </div>
                    <div className="status">Статус: {data.STATUS_NAME}</div>
                    <div className="comment">
                        <div>Текст:</div> 
                        <textarea disabled value={data.COMMENTS}></textarea>
                    </div> 
                    <div className="timeline">
                        <div className="timeline-title">Комментарии</div> 
                        {data.TIMELINE.map(item =>
                            <div key={item.ID} className="timeline-item">
                                <div className="timeline-name">{item.AUTHOR_NAME}:</div> 
                                <div className="timeline-text">{item.COMMENT}</div>
                            </div>
                        )} 
                        <AddComment id={data.ID} />
                    </div>
                </div>
            )
        }

    }
}
export default withRouter(Ticket);