import React, { useState, useRef, useEffect } from 'react';

const AddTicket = () => {
    const [message, setMessage] = useState('');
    const [name, setName] = useState();
    const [inn, setInn] = useState();
    const [mail, setMail] = useState();
    const [phone, setPhone] = useState();
    const [city, setCity] = useState();
    const [info, setInfo] = useState();
    const button = useRef();
    const [buttonText, setButtonText] = useState('Создать');
    const [showResults, setShowResults] = useState(false);
    
    async function CreateDeal(data, contact) {
        return fetch('https://lk.trbyte.ru/crm.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                    method: 'crm.deal.add',
                    id: data['token'],
                    name: data['name'],
                    text: data['fullText'],
                    category: data['category'],
                    contact: contact
                })
            })
            .then((resp) => resp.json())
            .then(function (data) {
                console.log(data.result);
                clearState();
            })
            .catch(function (error) {
                console.log(error);
                clearState();
            })
    }
    
    const clearState = () => {
        setButtonText('Создать');
        button.current.style.pointerEvents = "auto";
        setMessage('');
    }

    async function CreateTicket(data) {
        return fetch('https://lk.trbyte.ru/crm.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                    method: 'crm.contact.add',
                    name: data['name'],
                    phone:  data['phone'],
                    mail:  data['mail'],
                })
            })
            .then(resp => resp.json())
            .then(
                (result) => {
                    console.log(result);
                    CreateDeal(data, result.result);
                },
                (error) => {
                    console.log(error);
                }
            );    
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setButtonText('Загрузка...');
        button.current.style.pointerEvents = "none";
        let fullText = '';
        
        fullText += 'ИНН: ' + inn + ', ';
        fullText += 'Город: ' + city;
        if(info) fullText += ', Доп. инфо: ' + info;
        

        CreateTicket({
            name,
            token: '12022',
            fullText,
            category: '32',
            phone: phone,
            mail: mail,
        });
        setMessage(`Клиент добавлен!`);
        setShowResults(true);
    }


    const Message = () => (
        <div className="message">
            {message}
        </div>
      )

      useEffect(() => {
        if(message) {
            setTimeout(() => {
                setShowResults('false');
                
                setName('');
                setInn('');
                setMail('');
                setPhone('');
                setCity('');
                setInfo('');
            }, 5000);
        }
      },[message]);

    return(
        <form  className="tinkoff-form" onSubmit={handleSubmit} onClick={e => e.stopPropagation()}>
            { message ? <Message /> : null }
            <label>
                <input type="text" placeholder="ФИО клиента" value={name} onChange={e => setName(e.target.value)} />
            </label>
            <label>
                <input type="text" placeholder="ИНН*" required value={inn} onChange={e => setInn(e.target.value)} />
            </label>
            <label>
                <input type="text" placeholder="Почта клиента" value={mail} onChange={e => setMail(e.target.value)} />
            </label>
            <label>
                <input type="text" placeholder="Телефон клиента*" value={phone} required onChange={e => setPhone(e.target.value)} />
            </label>
            <label>
                <select onChange={e => setCity(e.target.value)} value={city}>
                    <option value="" disabled selected hidden>Выбор города:</option>
                    <option value="Воронеж">Воронеж</option>
                    <option value="Лиски">Лиски</option>
                    <option value="Россошь">Россошь</option>
                    <option value="Борисоглебск">Борисоглебск</option>
                    <option value="Поворино">Поворино</option>
                </select>
            </label>
            <label>
                <textarea placeholder="Информация" value={info} onChange={e => setInfo(e.target.value)} />
            </label>
            <div>
                <button type="submit" ref={button}>{buttonText}</button>
            </div>
        </form>
    )

}
export default AddTicket;