import React, { Component } from 'react';
import { Link } from 'react-router-dom';
export default class Tickets extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            error: null,
            isLoaded: false
        }
    }
    
    componentDidMount() {
        fetch('https://lk.trbyte.ru/crm.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                    method: 'crm.deal.list',
                    id: this.props.token,
                    category: 32
                })
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState(
                    {
                        isLoaded: true,
                        data: result.result
                    }
                );
            },
            (error) => {
                this.setState({
                    isLoaded: false,
                    error
                });
            }
        );
    }
    
    render() {
        const {error, isLoaded, data} = this.state;
        if (error) {
            return <p>{error.message}</p>
        } else if (!isLoaded) {
            return <p>Загрузка</p>
        } else {
            return (
                <div>
                    <ul className="tickets">
                        {data.map(item =>
                            <li key={item.ID}>
                                <div className="ticket-header">
                                    <Link to={'/tinkoff/' + item.ID} className="ticket-title">{item.TITLE}</Link>
                                    <div className="ticket-status">Статус: {item.STATUS_NAME}</div>
                                    <div className="ticket-create">Создана: {item.DATE_CREATE}</div>
                                    <div className="ticket-modify">Изменена: {item.DATE_MODIFY}</div>
                                    <div className="ticket-id">Номер обращения: {item.ID}</div>
                                </div>
                            </li>
                        )} 
                    </ul>
                </div>
            )
        }

    }
}
